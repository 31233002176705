@import "styles/constants.scss";

.TemperatureInput {
  font-size: $xxlarge-font-size;
  text-align: center;
  color: $accent;
  border-bottom: 3px solid $primary;
  min-height: 80px;
}

.Comma {
  font-size: $xxlarge-font-size;
  color: $accent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: center;
}